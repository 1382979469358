import 'bootstrap/dist/css/bootstrap.min.css';
import { MapContainer,TileLayer,Marker,Popup,useMapEvents } from 'react-leaflet';
import * as L from "leaflet";
import { useEffect,useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { useGeolocated } from "react-geolocated";

function App() {
  const[distributori, setDistributori] = useState(null);
  const[coord, setCoord] = useState(null);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
  
  const getCurrentPosition = () => {
    if(isGeolocationEnabled){
      if(isGeolocationAvailable){
        if(coords){
          console.log("Geolocation is available");
          return [coords.latitude,coords.longitude];
        }else{
          return [45.895270, 12.601000];
        }
      }
    }
    return [45.895270, 12.601000];
    
  }

  useEffect(() => {
    setCoord(getCurrentPosition());
    axios.post('/ospzApi/search/zone', {
        points: [{
            //45.895270, 12.601000
            lat: coord[0],
            lng: coord[1]
        }],
        fuelType: "1-1",
        priceOrder: "asc"
    })
    .then(function (response) {
        setDistributori(response.data.results);
    })
    .catch(function (error) {
        console.log(error);
    });
  } ,[])

  const LeafIcon = L.Icon.extend({
    options: {}
  });
  const redIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
  }), 
  greenIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"
  }),
  goldIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png"
  });
  const [icon, setIcon] = useState(goldIcon);
  
  return (
    <Container>
      <MapContainer center={coord} zoom={13} scrollWheelZoom={true} height={500}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {distributori && distributori.slice(0, 3).map(distributore => (
          <Marker key={distributore.id} position={[distributore.location.lat, distributore.location.lng]} icon={greenIcon}>
            <Popup>
              <div>
                <h3>{distributore.name}</h3>
                <p>{distributore.address}</p>
                {distributore.fuels.map(prezzo => (
                  <div key={prezzo.id}>
                    <p>{prezzo.name}: {prezzo.price}</p>
                  </div>
                ))}
              </div>
            </Popup>
          </Marker>
        ))}
        {distributori && distributori.slice(4, 6).map(distributore => (
          <Marker key={distributore.id} position={[distributore.location.lat, distributore.location.lng]} icon={goldIcon}>
            <Popup>
              <div>
                <h3>{distributore.name}</h3>
                <p>{distributore.address}</p>
                {distributore.fuels.map(prezzo => (
                  <div key={prezzo.id}>
                    <p>{prezzo.name}: {prezzo.price}</p>
                  </div>
                ))}
              </div>
            </Popup>
          </Marker>
        ))}
        {distributori && distributori.slice(7,10).map(distributore => (
          <Marker key={distributore.id} position={[distributore.location.lat, distributore.location.lng]} icon={redIcon}>
            <Popup>
              <div>
                <h3>{distributore.name}</h3>
                <p>{distributore.address}</p>
                {distributore.fuels.map(prezzo => (
                  <div key={prezzo.id}>
                    <p>{prezzo.name}: {prezzo.price}</p>
                  </div>
                ))}
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      
    </Container>
  );
}

export default App;
